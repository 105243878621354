import useGdprConsent from '../../hooks/useGdprConsent';
import { useEffect, useState } from 'react';
import { ICustomerConsentResponse } from 'components/Gdpr/requests/consentRequest';

declare const gtag: Function;

interface IEvent {
    type: string;
    detail: {};
}

const compositeCookies = {
    necessary: ['security_storage'],
    marketing: ['ad_storage', 'ad_user_data', 'ad_personalization'],
    preference: ['functionality_storage', 'personalization_storage'],
};

const updateGtmConsent = (customerConsent: ICustomerConsentResponse) => {
    const consentData: Record<string, 'granted' | 'denied'> = {};

    if (customerConsent.data.length === 0) {
        return;
    }

    customerConsent.data.forEach((cookie) => {
        const cookieCode = cookie.code;
        const isChecked = cookie.status ? 'granted' : 'denied';

        consentData[cookieCode] = isChecked;

        if (compositeCookies[cookieCode]) {
            compositeCookies[cookieCode].forEach((code) => (consentData[code] = isChecked));
        }
    });

    gtag('consent', 'update', consentData);
};

const handleCookieDataLayers = () => {
    const cookieMatch = document.cookie.match('(^|;)\\s*gtm-data-layers\\s*=\\s*([^;]+)');

    if (cookieMatch) {
        const cookieDataLayers = cookieMatch.pop();

        try {
            const parseDataLayers = JSON.parse(decodeURIComponent(cookieDataLayers ?? ''));

            if (Array.isArray(parseDataLayers) && parseDataLayers.length > 0) {
                parseDataLayers.forEach((dataLayer) => {
                    gtag(dataLayer.payload);
                });

                document.cookie = 'gtm-data-layers=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/';
            }
        } catch (e) {
            console.error(e);
        }
    }
};

const handleServerDataLayers = () => {
    const gtmContext = getGtmContextFromStorage();
    const dataLayers = window.serverDataLayers ?? [];

    dataLayers.forEach((serverDataLayer) => {
        if (['view_cart', 'begin_checkout', 'purchase'].includes(serverDataLayer.event)) {
            serverDataLayer.ecommerce.items.map((item) => {
                const gtmContextProduct = gtmContext[item.item_id] ?? {};

                Object.assign(item, gtmContextProduct);
            });
        }

        gtag(serverDataLayer);
    });
};

const handleViewItem = (e) => {
    const details = e.detail;

    if (details) {
        const product = details.item;

        gtag({
            event: 'view_item',
            user_id: details.userId,
            ecommerce: {
                currency: window.STORE_CURRENCY_CODE,
                items: [
                    {
                        item_id: product.sku,
                        item_name: product.name,
                        price: product.price,
                    },
                ],
            },
        });
    }
};

const handleViewItemList = (e) => {
    const details = e.detail;

    if (details) {
        gtag({
            event: 'view_item_list',
            user_id: details.userId,
            ecommerce: {
                item_list_id: details.itemListId,
                item_list_name: details.itemListName,
                currency: window.STORE_CURRENCY_CODE,
                items: details.items.map((product) => {
                    return {
                        item_id: product.sku,
                        item_name: product.name,
                        price: product.price,
                    };
                }),
            },
        });
    }
};

interface IGtmProduct {
    item_id: string;
    item_name: string;
    quantity: number;
    price: number;
    item_list_id: string;
    item_list_name: string;
}

const handleCartUpdated = (e) => {
    const details = e.detail;
    const gtmContext = getGtmContextFromStorage();

    if (details && (details.action === 'add-to-cart' || details.action === 'remove-from-cart')) {
        const gtmEventFromAction = {
            'add-to-cart': 'add_to_cart',
            'remove-from-cart': 'remove_from_cart',
        };

        const gtmProducts: IGtmProduct[] = [];

        // batch action
        if (details.batchProducts) {
            details.batchProducts.forEach((gtmProduct) => {
                gtmContext[gtmProduct.sku] = {
                    item_list_id: gtmProduct.itemListId,
                    item_list_name: gtmProduct.itemListName,
                };

                gtmProducts.push({
                    item_id: gtmProduct.sku,
                    item_name: gtmProduct.name,
                    quantity: gtmProduct.qty || 1,
                    price: parseFloat(gtmProduct.price),
                    item_list_id: gtmProduct.itemListId,
                    item_list_name: gtmProduct.itemListName,
                });
            });
        } else {
            gtmContext[details.sku] = {
                item_list_id: details.itemListId,
                item_list_name: details.itemListName,
            };

            gtmProducts.push({
                item_id: details.sku,
                item_name: details.name,
                quantity: details.qty || 1,
                price: details.price,
                item_list_id: details.itemListId,
                item_list_name: details.itemListName,
            });
        }

        setGtmContextToStorage(gtmContext);

        gtag({
            event: gtmEventFromAction[details.action],
            user_id: details.userId,
            ecommerce: {
                currency: window.STORE_CURRENCY_CODE,
                value: getItemsValue(gtmProducts),
                items: gtmProducts,
            },
        });
    }
};

const handleCheckoutStep = (e) => {
    const details = e.detail;
    const gtmContext = getGtmContextFromStorage();

    if (details) {
        const gtmEventFromCheckoutAction = {
            'add-shipping-info': 'add_shipping_info',
            'add-contact-info': 'add_contact_info',
            'add-payment-info': 'add_payment_info',
        };
        const customer = details.customer;
        const gtmProducts = details.items.map(function (item) {
            const gtmContextProduct = gtmContext[item.sku] ?? {};

            return {
                item_id: item.sku,
                item_name: item.name,
                image: item.image,
                quantity: item.qty || 1,
                price: item.price,
                ...gtmContextProduct,
            };
        });
        const gtmData = {
            event: gtmEventFromCheckoutAction[details.action],
            user_id: customer.id,
            email: details.email,
            ecommerce: {
                currency: window.STORE_CURRENCY_CODE,
                value: getItemsValue(gtmProducts),
                items: gtmProducts,
                shipping_tier: '',
                payment_type: '',
            },
        };

        if (details.action === 'add-shipping-info') {
            gtmData.ecommerce.shipping_tier = details.method.methodCode;
        } else if (details.action === 'add-payment-info') {
            gtmData.ecommerce.payment_type = details.method.methodCode;

            if (details.method.methodName) {
                gtmData.ecommerce.payment_type += `-${details.method.methodName}`;
            }
        }

        gtag(gtmData);
    }
};

const handleCookieConsent = (e) => {
    const details = e.detail;

    if (details) {
        const gtmData = {
            event: 'cookie_consent_update',
            user_id: details.customer ? details.customer.id : null,
        };

        details.consentData.forEach(function (cookie) {
            gtmData[`cookie_${cookie.code}`] = cookie.status ? 1 : 0;
        });

        gtag(gtmData);
    }
};

const getGtmContextFromStorage = () => {
    const gtmContext = window.sessionStorage.getItem('gtm_context');

    if (gtmContext) {
        try {
            return JSON.parse(gtmContext);
        } catch (e) {}
    }

    return {};
};

const setGtmContextToStorage = (gtmContext) => {
    window.sessionStorage.setItem('gtm_context', JSON.stringify(gtmContext));
};

const getItemsValue = (items) => {
    let sum = 0;

    items.forEach((item) => {
        sum += (item.quantity || 1) * item.price;
    });

    return parseFloat(sum.toFixed(2));
};

export default function GtagEvents() {
    const { isFinished, status, customerConsent, hasConsent } = useGdprConsent();
    const [eventQueue, setEventQueue] = useState<IEvent[]>([]);

    const handleAddToQueue = (e) => {
        setEventQueue((prevState) => {
            prevState.push({
                type: e.type,
                detail: e.detail,
            });

            return prevState;
        });
    };

    const handleQueue = () => {
        eventQueue.forEach((event) => {
            window.dispatchEvent(new CustomEvent(event.type, { detail: event.detail }));
        });

        setEventQueue([]);
    };

    useEffect(() => {
        if (isFinished) {
            if (customerConsent.data.length === 0) {
                gtag('consent', 'default', {
                    necessary: 'granted',
                    security_storage: 'granted',
                    preference: 'denied',
                    marketing: 'denied',
                    analytics_storage: 'denied',
                    ad_storage: 'denied',
                    ad_user_data: 'denied',
                    ad_personalization: 'denied',
                    functionality_storage: 'denied',
                    personalization_storage: 'denied',
                });
            } else {
                updateGtmConsent(customerConsent);
            }

            window.removeEventListener('view-item', handleAddToQueue);
            window.removeEventListener('view-item-list', handleAddToQueue);

            window.addEventListener('view-item', handleViewItem);
            window.addEventListener('view-item-list', handleViewItemList);
            window.addEventListener('cartUpdated', handleCartUpdated);
            window.addEventListener('checkout-step-proceed', handleCheckoutStep);
            window.addEventListener('cookie-consent-saved', handleCookieConsent);

            handleQueue();
            handleCookieDataLayers();
            handleServerDataLayers();
        } else {
            window.addEventListener('view-item', handleAddToQueue);
            window.addEventListener('view-item-list', handleAddToQueue);
        }
    }, [isFinished, customerConsent]);

    return null;
}
