import React from 'react';
import Header from 'andbeauty-ui/lib/Applications/eBeauty/Components/Header';
import AccessibleDrop from 'andbeauty-ui/lib/Components/AccessibleDrop/index';
import MainMenuWrapper from 'components/ui/MainMenuWrapper';
import CustomerRegisterForm from 'components/Customer/Form/Register';
import CustomerLoginForm from 'components/Customer/Form/Login';
import Messages from 'components/Messages/Messages';
import ResetForgottenPasswordForm from 'components/Customer/Form/ResetForgottenPassword';
import ForgotPassword from 'components/Customer/Form/ForgotPassword';
import UserHubMobile from 'components/UserHub/UserHubMobile';
import TopSearch from 'components/CatalogSearch/TopSearch';
import TopSearchMobile from 'components/CatalogSearch/TopSearchMobile';
import MiniCartButton from 'components/Checkout/MiniCartButton';
import ProductList from 'components/Catalog/Product/ProductList';
import AuthenticationPopup from 'components/Customer/Auth/AuthenticationPopup';
import LoginButton from 'components/Customer/Auth/LoginButton';
import FacebookLoginButton from 'components/SocialLogin/FacebookLoginButton';
import GoogleLoginButtonProvider from 'components/SocialLogin/GoogleLoginButtonProvider';
import BrandMenuButton from 'components/BrandMenu/BrandMenuButton';
import MiniCartPopup from 'components/Checkout/MiniCartPopup';
import NotificationWidget from 'components/Widget/NotificationWidget';
import LayeredNavigation from 'components/LayeredNavigation/LayeredNavigation';
import NewsletterForm from 'components/Newsletter/NewsletterForm';
import Carousel from 'andbeauty-ui/lib/Components/RenderCarousel/index';
import SampleProductHeaderCallout from 'components/SampleProduct/SampleProductHeaderCallout';
import EventListeners from 'components/EventListeners/EventListeners';
import SampleProductPopup from 'components/SampleProduct/SampleProductPopup';
import Form from 'components/Checkout/Cart/Form';
import Sidebar from 'components/Checkout/Cart/Sidebar';
import SampleProductCartCallout from 'components/SampleProduct/SampleProductCartCallout';
import CategorySlider from 'components/CategorySlider/CategorySlider';
import Gallery from 'components/Catalog/Product/Gallery';
import ProductBadgesRenderer from 'components/Catalog/ProductBadge/ProductBadgesRenderer';
import ProductView from 'components/Catalog/Product/ProductView';
import Tabs from 'components/ui/Tabs/Tabs';
import RelatedProducts from 'components/RelatedProducts/RelatedProducts';
import CartShareButton from 'components/CartShare/CartShareButton';
import CartSharePopup from 'components/CartShare/CartSharePopup';
import QuoteReservationTimerWrapper from 'components/QuoteReservation/QuoteReservationTimerWrapper';
import Review from 'components/Review/Review';
import ConsentHandler from 'components/Gdpr/ConsentHandler';
import Toolbar from 'components/ui/TableTools/Toolbar';
import CustomerAddressForm from 'components/Customer/Address/Form';
import CustomerEditForm from 'components/Customer/Form/Edit';
import SocialLoginAttachButtons from 'components/SocialLogin/AttachButtons';
import PostDataHelper from 'components/PostDataHelper/PostDataHelper';
import ConsentPopup from 'components/Gdpr/ConsentPopup';
import BestSellerSlider from 'components/BestSellerSlider/BestSellerSlider';
import InstagramFeed from 'components/InstagramFeed/InstagramFeed';
import CheckoutProvider from 'components/Checkout/CheckoutProvider';
import AuthorizationHandler from 'components/Authorization/AuthorizationHandler';
import CtaPopup from 'components/CtaPopup/CtaPopup';
import GtagEvents from 'components/SeoSuite/GtagEvents';
import KlaviyoCustomerData from 'components/SeoSuite/KlaviyoCustomerData';

const componentMapper = (
    maybeComponent: string,
    maybeContent: string,
    maybeConfig,
    ignoreTabs: string[] = [],
): React.Component => {
    return {
        header: <Header dom={maybeContent} config={maybeConfig} componentMapper={componentMapper} />,
        accessibleDrop: <AccessibleDrop dom={maybeContent} config={maybeConfig} componentMapper={componentMapper} />,
        menu: <MainMenuWrapper dom={maybeContent} config={maybeConfig} componentMapper={componentMapper} />,
        authenticationPopup: <AuthenticationPopup config={maybeConfig} componentMapper={componentMapper} />,
        loginButton: <LoginButton config={maybeConfig} />,
        facebookLoginButton: <FacebookLoginButton config={maybeConfig} />,
        googleLoginButton: <GoogleLoginButtonProvider config={maybeConfig} />,
        socialLoginAttachButtons: <SocialLoginAttachButtons config={maybeConfig} />,
        customerAccountForm: <CustomerEditForm config={maybeConfig} />,
        customerRegisterForm: <CustomerRegisterForm config={maybeConfig} />,
        customerLoginForm: <CustomerLoginForm config={maybeConfig} />,
        customerAddressForm: <CustomerAddressForm config={maybeConfig} />,
        messages: <Messages config={maybeConfig} />,
        userHubMobile: <UserHubMobile config={maybeConfig} />,
        topSearch: <TopSearch config={maybeConfig} />,
        topSearchMobile: <TopSearchMobile config={maybeConfig} />,
        customerAccountResetForgottenPasswordForm: <ResetForgottenPasswordForm config={maybeConfig} />,
        customerForgotPasswordForm: <ForgotPassword config={maybeConfig} />,
        checkoutCartForm: <Form config={maybeConfig} />,
        checkoutCartSidebar: <Sidebar config={maybeConfig} />,
        miniCartButton: <MiniCartButton config={maybeConfig} />,
        miniCartPopup: <MiniCartPopup config={maybeConfig} />,
        productList: <ProductList config={maybeConfig} />,
        productView: <ProductView config={maybeConfig} />,
        productGallery: <Gallery config={maybeConfig} />,
        productBadges: <ProductBadgesRenderer config={maybeConfig} />,
        brandsMenuButton: <BrandMenuButton config={maybeConfig} />,
        notificationWidget: <NotificationWidget config={maybeConfig} />,
        layeredNavigationView: <LayeredNavigation config={maybeConfig} />,
        newsletterForm: <NewsletterForm config={maybeConfig} />,
        carousel: <Carousel dom={maybeContent} config={maybeConfig} componentMapper={componentMapper} />,
        sampleProductHeaderCallout: <SampleProductHeaderCallout config={maybeConfig} />,
        sampleProductCartCallout: <SampleProductCartCallout config={maybeConfig} />,
        sampleProductPopup: <SampleProductPopup config={maybeConfig} />,
        eventListeners: <EventListeners />,
        categorySlider: <CategorySlider config={maybeConfig} />,
        tabs: <Tabs config={maybeConfig} />,
        relatedProducts: <RelatedProducts config={maybeConfig} />,
        cartShareButton: <CartShareButton config={maybeConfig} />,
        cartSharePopup: <CartSharePopup config={maybeConfig} />,
        quoteReservationTimer: <QuoteReservationTimerWrapper config={maybeConfig} />,
        review: <Review config={maybeConfig} />,
        gtagEvents: <GtagEvents />,
        klaviyoCustomerData: <KlaviyoCustomerData />,
        gdprConsentHandler: <ConsentHandler />,
        gdprConsentPopup: <ConsentPopup config={maybeConfig} />,
        tableToolbar: <Toolbar config={maybeConfig} />,
        postDataHelper: <PostDataHelper />,
        bestSellerSlider: <BestSellerSlider config={maybeConfig} />,
        instagramFeed: <InstagramFeed config={maybeConfig} />,
        checkout: <CheckoutProvider />,
        authorization: <AuthorizationHandler config={maybeConfig} />,
        ctaPopup: <CtaPopup dom={maybeContent} config={maybeConfig} />,
    }[(ignoreTabs.includes(maybeComponent) ? '' : maybeComponent) || ''];
};

export default componentMapper;
