import useAuth from '../../hooks/useAuth';
import { useEffect } from 'react';

export default function KlaviyoCustomerData() {
    const { customer } = useAuth();

    useEffect(() => {
        if (!window.klaviyo) {
            return;
        }

        const klaviyo = window.klaviyo;

        klaviyo.isIdentified().then((identified) => {
            if (customer && customer.email && !identified) {
                klaviyo.identify({
                    $email: customer.email,
                    $first_name: customer.firstName,
                    $last_name: customer.lastName,
                });
            }
        });
    }, [customer]);
    return null;
}
